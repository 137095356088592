import * as React from 'react';
import ReactLoader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Loader = (): React.ReactElement => {
  return <ReactLoader color="#ffa7c4" height={12} type="ThreeDots" />;
};

export default Loader;
